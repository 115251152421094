// Basic Color Palette: https://coolors.co/2d2d2a-353831-38423b-3f5e5a-20fc8f
$normal-orange-color: #F96655;
$normal-black-color: #25313B;
$normal-gray-color: #677D80;
$normal-green-color: #64E4D2;

// Other Colors
$dark-orange-color: #F62109;
$dark-green-color: #22C3AE;
$darker-green-color: #1B9887;
$darkest-green-color: #136C60;
$aqua-marine-color: #41BEAE;

// Functional Colors
$normal-link-color: $darker-green-color;
$hover-link-color: $darkest-green-color;

$normal-nav-dropdown-toggle-caret: $normal-link-color;
$hover-nav-dropdown-toggle-caret: $normal-link-color;
$active-nav-dropdown-toggle-caret: $normal-link-color;
$active-nav-tabs-dropdown-toggle-caret: $normal-link-color;

/* Overrides */
body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: $normal-black-color;
  background-color: #ffffff;
}

.container-black {
  background-color: $normal-black-color;
  opacity: 1;
}

.navbar-inverse .nav > li > a {
  color: #999999;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

a {
  color: $normal-link-color;
  text-decoration: none;
}

a:hover {
  color: $hover-link-color;
  text-decoration: underline;
}

.nav > li > a:hover {
  color: $hover-link-color;
  text-decoration: underline;
  /* background-color: #eeeeee; */
  background-color: #e6e6e6;
}

.nav-list > .active > a,
.nav-list > .active > a:hover {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: $normal-black-color;
}

.nav .dropdown-toggle .caret {
  margin-top: 6px;
  border-top-color: $normal-nav-dropdown-toggle-caret;
  border-bottom-color: $normal-nav-dropdown-toggle-caret
}

.nav .dropdown-toggle:hover .caret {
  border-top-color: $hover-nav-dropdown-toggle-caret;
  border-bottom-color: $hover-nav-dropdown-toggle-caret;
}


.nav .active .dropdown-toggle .caret {
  border-top-color: $active-nav-dropdown-toggle-caret;
  border-bottom-color: $active-nav-dropdown-toggle-caret;
}

.nav-tabs .active .dropdown-toggle .caret {
  border-top-color: $active-nav-tabs-dropdown-toggle-caret;
  border-bottom-color: $active-nav-tabs-dropdown-toggle-caret;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
  color: #ffffff;
  text-decoration: none;
  background-color: $normal-black-color;
  background-image: none;
}

.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: $normal-black-color;
  background-image: none;
}

.btn-primary {
  color: #F96655;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
  border-color: #F96655;
  background-image: none;
}


.btn-primary:hover,
.btn-primary.hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.disabled,
.btn-primary[disabled],
.btn-group.open .btn.dropdown-toggle {
  color: #f73722;
  background-color: rgba(0, 0, 0, 0.0470588);
  *background-color: rgba(0, 0, 0, 0.0470588);
  border-color: #f73722;
}

.btn-primary:active,
.btn-primary.active,
.btn-group.open .btn.dropdown-toggle {
  background-color: #0e7e9f \9;
}

/* New */
.top-banner {
  color: #ffffff;
  background-color: $normal-black-color;
}

.content-height {
  // available height = 100vh - (header height + banner height + footer height)
  height: calc(100vh - 392px);
}
